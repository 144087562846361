import React, { useState, useEffect} from 'react';
import styled from 'styled-components';
import image from '../Images/Wheat.png'
import {  Row, Col } from 'reactstrap';
import image1 from '../Images/Climate.png'
import image2 from '../Images/ClimatePortal.PNG'
import ArrowBackIosIcon from '../Images/ArrowBack.png';
import { useNavigate } from 'react-router-dom';

const Icon = styled.img`
  position: absolute;
  display: inline-block;
  top: 92px;
  left: 102px;
 height:43px;
 width:'25px';

  cursor: pointer;
  z-index: 3;

    @media (max-width: 767px) {
    font-size: 36px!important;
   top: 111px;
     left: 57px;
  }
`;
const StyledParagraph = styled.p`
  font-size: 20px;
  text-align: center;
   width: 50%; // Default width for larger devices
   margin-right: 219px;
    margin-left: -225px;

  @media (max-width: 767px) {
    /* Media query for smaller devices */
   margin:auto;
    width: 100%;
  }
`;
/*const TopNav = styled.div`
  overflow: hidden;
   text-align: center;
  padding: 20px;

  display:flex;
  justify-content:center;
  align-item:center;
`;

const StyledNavLink = styled(NavLink)`
  float: left;
  display: block;
  color: black;
  text-align: center;
  padding: 14px 48px;
  text-decoration: none;
  font-size: 18px;
  border-bottom: 1px solid gray;

   @media (max-width: 767px) {
    font-size: 14px;
    padding: 10px 28px;
   

    
  }

  &:hover {
    border-bottom: 3px solid black;
  }

  &.active {
    border-bottom: 3px solid black;
  }
`;

const ContentContainer = styled.div`
  padding: 20px;
  margin: auto;
  width: 50%;
`;*/
const StyledImage = styled.img`
  width: 249.6px;
  height: 249.6px;
  margin-left: -89px;
  margin-right: 12px;
  margin-bottom: -95px;
    margin-top: 0px;
   @media (max-width: 767px) {
    /* Media query for smaller devices */
  margin-bottom: -128px;
    margin-left: -278px;
  }
`;


export const FoodSecurity = () => {
    const navigate = useNavigate();
    return (
        <>
            <div style={{ flex: 1, minHeight: 'calc(100vh - 50px)' }} className="mt-5 mt-md-0">
                <div style={{ border: "8px solid black", marginBottom: "25px", marginTop: "25px", marginRight: "35px", marginLeft: "35px" }}>
                <div className=" d-flex align-items-center justify-content-center" >
                        <p style={{fontSize:'24px'}} className="mt-3 mb-4 text-center">
                       <b>FOOD</b> SECURITY

                    </p>
                        <div className=" d-flex align-items-center justify-content-center ">
                            <Icon src={ArrowBackIosIcon}
                                onClick={() => { navigate('/'); }} />
                         
                        </div>
            </div>

            <Row className=" d-flex  align-items-center justify-content-center">

                <Col className="text-center my-3" xs={ 12} lg={7}>
    <div
        style={{
            width: '100%',
            position: 'relative',
            overflow: 'hidden',
            paddingTop: '56.25%', 
        }}
    >
        <iframe
            title="Climate Change Video"
            width="853"
            height="480"
            src="https://www.youtube.com/embed/UC8-vRSFPIs"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
            }}
        ></iframe>
    </div>
</Col>

            </Row>
            <Col lg={12} className="d-flex flex-column flex-md-row justify-content-center align-items-center m-5 m-md-0">
                        <img src={image} alt="whatsapp" style={{ width: '238.08px', height: '172.8px', margintop: "120px", marginLeft: '12px', marginRight: '12px'}} />
                        <StyledParagraph className="ms-0"  >
                    We endeavor to provide food to the poor in the developing countries. You can <b>take this responsibility with us...</b>
</StyledParagraph>

                    </Col>
                    <div className="mb-2" style={{ textAlign: 'right', marginTop: "-36px", }} >
                        <a href="https://forms.gle/vQHsj4UfJUTdqeK7A " target="_blank"  className="me-0 me-md-5" style={{ textAlign: 'right', color: "green",  textDecoration: 'none', fontSize: "20px" }}><i><b>{"Become a Volunteer >>"}
                        </b></i></a></div>
                </div>
            </div>
        </>
    );
};


const ClimateChange = () => {
    const navigate = useNavigate();
    return (
        <>
            <div style={{ flex: 1, minHeight: 'calc(100vh - 50px)' }} className="mt-5 mt-md-0">
                <div style={{ border: "8px solid darkgreen", marginBottom: "25px", marginTop: "25px" , marginRight: "35px", marginLeft: "35px"  }}>
                    <div className=" d-flex align-items-center justify-content-center" >
                        <p style={{ fontSize: '24px' }} className="mt-3 mb-4 text-center">
                            <b>CLIMATE</b> CHANGE

                        </p>
                        <div className=" d-flex align-items-center justify-content-center ">
                            <Icon src={ArrowBackIosIcon}
                                onClick={() => { navigate('/'); }} />

                        </div>
                    </div>

                    <Row className=" d-flex  align-items-center justify-content-center">

                        <Col className="text-center mt-3" xs={12} lg={7}>
                            <div
                                style={{
                                    width: '100%',
                                    height:"180%",
                                    position: 'relative',
                                    overflow: 'hidden',
                                    paddingTop: '56.25%',
                                    borderRadius:"20px"
                                }}
                            >
                                <a href="https://climateknowledgeportal.worldbank.org/" target="_blank" rel="noopener noreferrer">
                                    <img
                                        width="100%"
                                        height="100%"
                                        src={image2}
                                        alt="Climate"
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                        }}
                                        className="img-fluid"
                                    />
                                </a>
                            </div>
                        </Col>

                    </Row>
                    <Col lg={12} className="d-flex flex-column-reverse flex-md-row justify-content-center align-items-center mt-5 mt-md-0 mx-5 mx-md-0">
                        <StyledImage src={image1} alt="whatsapp" />
                        <StyledParagraph className="w-100 me-0" >
                            We plant trees and welcome volunteers to be a part of 
                            <b> this effort.</b>
                        </StyledParagraph>

                    </Col>
                    <div className="mb-5 mb-md-2" style={{ textAlign: 'right' }} >
                        <a href="https://forms.gle/vQHsj4UfJUTdqeK7A" target="_blank" className="me-0 me-md-5 "  style={{ textAlign: 'right', color: "green",  textDecoration:'none', fontSize:"20px" }}><i><b>{"Become a Volunteer >>"}
                    </b></i></a></div>
                </div>
            </div>
            <p style={{marginTop:'-20px', fontSize:'18px', marginLeft:'45px'} }>Respect <b><i>Nature</i></b></p>
        </>
    );
};


const Navigation = (props) => {
    const [activeSection, setActiveSection] = useState("climate");
  

    useEffect(() => {
        if (props && props.nav) {
            setActiveSection(props.nav);
        }
    }, [props]);

    return (

        <div style={{
           flex: 1, minHeight: 'calc(100vh - 100px)'}}>
            {/*<TopNav>
                <StyledNavLink
                    exact
                    to="/climate"
                    onClick={() => handleNavLinkClick("climate")}
                >
                    Climate Change
                </StyledNavLink>
                <StyledNavLink
                    to="/capacity"
                    onClick={() => handleNavLinkClick("capacity")}
                >
                    Capacity Building
                </StyledNavLink>
                <StyledNavLink
                    to="/food"
                    onClick={() => handleNavLinkClick("food")}
                >
                    Food Security
                </StyledNavLink>
            </TopNav>*/}

            
                {activeSection === "climate" && <ClimateChange />}
           
                {activeSection === "food" && <FoodSecurity />}
          
                </div>
    
    );
};

export default Navigation;
