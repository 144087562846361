import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Overlay from './Overlay';
import ArrowBackIosIcon from '../Images/ArrowBack.png';

const Icon = styled.img`
  position: absolute;
  display: inline-block;
  top: 23px;
  left: 30px;
 height:43px;
 width:'25px';

  cursor: pointer;
  z-index: 3;

    @media (max-width: 767px) {
    font-size: 36px!important;
   top: 23px;
     left: 10px;
  }
`;

const Text = styled.p`
 position: absolute;
  font-size: 21px;
  margin-left: 14px;
   top: 29px;
  left: 46px;

    @media (max-width: 767px) {
    font-size: 16px;
 top: 32px;
     left: 20px;
  }
`;

 
const Hiring = () => {
    const navigate = useNavigate();
    return (
        <div>
            <Overlay>
                <div className=" d-flex align-items-center justify-content-center ">
                    <Icon src={ArrowBackIosIcon}
                        onClick={() => { navigate('/'); }} />
                    <Text onClick={() => { navigate('/'); }}>BACK</Text>
                </div>
               

            <Container className="mt-5" style={{ textAlign: 'justify' }}>
                    <Row className="d-flex justify-content-center align-items-center">

               
                        <Col xs={12} lg={7}>

                            <article>
                                <p style={{ fontSize: '21px' }}><b>HIRING PROCESS</b></p>
                            We take pride in our commitment to recruiting and nurturing top talent in the industry.
                            This comprehensive guide outlines our structured approach to hiring the best candidates to meet our clients' unique needs.
                            Our hiring process is designed to identify, evaluate, and develop individuals who will thrive in our dynamic work environment and deliver exceptional results for our clients.
                        </article>
                    </Col>
                </Row>
       
                    <Row className="mt-4 d-flex justify-content-center align-items-center">
               

                        <Col className="d-flex " xs={12} lg={7}>
                    
                        <article>
                            <h6><b>Engagement with Headhunting Firm</b></h6>
                            At InstaRep, we recognize the importance of engaging top-notch talent from the start.
                            We partner with a reputable headhunting firm that specializes in identifying and approaching the best professionals in the industry.
                            This partnership ensures a wide reach and access to potential candidates who have demonstrated excellence in their respective fields.
                        </article>

                    </Col>
                    </Row>
                    <Row className="mt-4 d-flex justify-content-center align-items-center">

             


                        <Col className="d-flex " xs={12} lg={7}>
                   
                        <article>
                            <h6><b>Specialist Panel Selection</b></h6>
                            Our specialized panel of industry experts who meticulously review and assess potential candidates.
                            They shortlist individuals based on their qualifications, experience, and alignment with our client's requirements.
                            This step ensures that only the most qualified candidates progress to the next phase of our hiring process.
                        </article>
                    </Col>
                </Row>
                    <Row className="mt-4 d-flex justify-content-center align-items-center">


                        <Col className="d-flex " xs={12} lg={7}>
                      
                        <article>
                            <h6><b>Evaluation by Head of Department</b></h6>

                            Selected candidates are then introduced to our Head of Department, who plays a pivotal role in evaluating their suitability for the position.
                            This evaluation is conducted to the highest standards, considering not only the candidates' technical skills but also their behavioural fit.
                            It is ensured that each candidate aligns with our values and is committed to delivering excellence.
                        </article>
                    </Col>
                </Row>
                    <Row className="mt-4 d-flex justify-content-center align-items-center">

             

                        <Col className="d-flex " xs={12} lg={7}>
                      
                        <article>
                            <h6><b>Training Program Enrolment</b></h6>

                            Once the Head of Department approves a candidate, they are invited to enrol in our comprehensive training program.
                            This program is designed to equip candidates with the specific skills and knowledge required to excel in their roles and meet our clients' needs effectively.
                            It includes a combination of technical training, soft skills development, and exposure to our company's unique processes and culture.
                        </article>
                    </Col>
                </Row>
                    <Row className="mt-4 d-flex justify-content-center align-items-center">

            
                        <Col className="d-flex " xs={12} lg={7}>
                      
                        <article>
                            <h6><b>Test</b> </h6>

                            Following the completion of our comprehensive training program, candidates undergo a thorough evaluation to measure the depth of their learning and preparedness 
                            for their roles. 
                        </article>
                    </Col>
                </Row>
                    <Row className="mt-4 d-flex justify-content-center align-items-center">



                        <Col className="d-flex " xs={12} lg={7}>
                 
                        <article>
                            <h6><b>Ongoing Development and Support</b></h6>

                            Our commitment to our employees doesn't end with their initial training. We believe in continuous development and support to help our staff thrive in their roles.
                            We provide access to ongoing training opportunities, mentorship, and a nurturing work environment that encou where they apply their newly acquired skills and knowledge to meet our clients' needs effectively.
                            Throughout their tenure with us, we maintain open lines of communication with clients to ensure satisfaction and address any concerns promptly.
                        </article>
                    </Col>
                </Row>
                    <Row className="mt-4 mb-5 d-flex justify-content-center align-items-center">

               



                        <Col className="d-flex " xs={12} lg={7}>
                        
                        <article>
               
                            InstaRep's hiring Framework is built on a foundation of excellence, starting with our partnership with a finest headhunting firm, rigorous candidate selection,
                            thorough evaluation, and comprehensive training. We are committed to nurturing and developing top talent to deliver exceptional results for our clients.
                            We believe in the power of our people to drive success, and this framework is a testament to that commitment.
                        </article>
                    </Col>
                </Row>

                </Container>
            </Overlay>
        </div>
    );
};

export default Hiring;