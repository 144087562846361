import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Diversity1 as Diversity1Icon, PersonSearch as PersonSearchIcon, ManageAccounts as ManageAccountsIcon, } from '@mui/icons-material';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import Overlay from './Overlay';
import image from '../Images/55-removebg-preview.png'
import { Button } from 'reactstrap';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import ResponsiveNav from './ResponsiveNav';

import ArrowBackIosIcon from '../Images/ArrowBack.png';



const Icon = styled.img`
  position: absolute;
  display: inline-block;
  top: 23px;
  left: 30px;
 height:43px;
 width:'25px';

  cursor: pointer;
  z-index: 3;

    @media (max-width: 767px) {
    font-size: 36px!important;
   top: 23px;
     left: 10px;
  }
`;

const Text = styled.p`
 position: absolute;
  font-size: 21px;
  margin-left: 14px;
   top: 29px;
  left: 46px;

    @media (max-width: 767px) {
    font-size: 16px;
 top: 32px;
     left: 20px;
  }
`;

/*const Icon = styled(CloseIcon)`
  position: fixed;
  top: 20px;
  left: 20px;
  font-size: 24px;
  cursor: pointer;
  z-index: 3;
`;*/

    
const WhyInstarep = () => {
    const navigate = useNavigate();
    return (
        <div>
            <Overlay>
                <div className=" d-flex align-items-center justify-content-center ">
                    <Icon src={ArrowBackIosIcon}
                        onClick={() => { navigate('/why-instarep-home'); }} />
                    <Text>BACK</Text>
                </div>
      
              


                <Container className="mt-5 text-justify p-0" style={{ textAlign: 'justify' }}>
                    <Row className="d-flex justify-content-center align-items-center">
               


                        <Col className="d-flex " xs={12} lg={7}>
                            
                            <article>
                                <p style={{ fontSize: '21px' }}><b>WHY INSTAREP</b></p>
                                <h6><b>Qualified Professionals</b></h6>

                            Our staff members are carefully selected, highly qualified, and possess a wealth of experience in their respective fields.
                        </article>
                    </Col>
                </Row>
                    <Row className="mt-4 d-flex justify-content-center align-items-center">

                  


                        <Col className="d-flex " xs={12} lg={7}>
                
                        <article>
                                <h6><b>Rigorous Selection Process</b></h6>

                            We leave no room for compromise when it comes to selecting the right talent. Our rigorous selection process ensures the best candidates.
                        </article>
                    </Col>
                </Row>
                    <Row className="mt-4 d-flex justify-content-center align-items-center">

                


                        <Col className="d-flex " xs={12} lg={7}>
            
                        <article>
                                <h6><b>Strategic Alignment</b></h6>

                            We understand that your success hinges on the execution of your strategy.
                            Our staff members are not just employees; they become integral parts of your team, working diligently to bring your vision to life and drive continuous growth.
                        </article>
                    </Col>
                </Row>
                    <Row className="mt-4 mb-5 d-flex justify-content-center align-items-center">

               

                        <Col xs={12} lg={7} >
                        
                            <article  >
                            At InstaRep, we are more than a staffing agency; we are your partners in progress.
                            Trust us to deliver the best talent that will contribute to your business's success, ensuring that you remain at the forefront of your industry.

                            Contact us today to explore how we can empower your agency with the right talent for sustainable growth. Your success is our priority.

                        </article>
                    </Col>
                </Row>
                </Container>
            </Overlay>
        </div>
    );
};

const WhyInstarepHome = () => {

 
    return (
        <>
        <ResponsiveNav />
        <div style={{ flex: 1, minHeight: 'calc(100vh - 176px)' }}>

     


            <Row className="d-flex flex-column justify-content-center align-items-center">
                  
                        <p className=" mb-0 text-center"  style={{ fontSize: '24px' }}><b>WHY </b>INSTAREP</p>
                    <Col xs={12} md={4} lg={4} xl={3} className='p-0'>
                        <img src={image} alt="home" style={{ alignSelf: 'center' }} className=" m-0 img-fluid" />
                    </Col>
                    <Col xs={12} md={11} lg={10} xl={8 }>
                        <div className="text-center " style={{ marginTop: '-11px' }}>
                            <p style={{fontSize:'24px'} }><i>We help you to land clients, deliver amazing and systematized results to retain your clients at the highest level and <span style=
                                {{ color: 'rgb( 221, 159, 0)', fontWeight: 'bold' }}>scale your business</span></i> </p>
                        </div>
                      
                        <NavLink tag={Link} className=" px-4 text-dark text-center d-flex flex-column justify-content-center mb-3 mt-4 mt-lg-3 mt-xl-3" to="/why-instarep">
                            <Button className=" px-3 "
                                color="dark"
                                style={{
                                    borderRadius: '30px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '5px 10px 5px 10px',
                                    margin: 'auto',
                                    



                                }}
                                //onClick={() => { navigate('/why-instarep'); }}
                            >
                                <span style={{ verticalAlign: 'middle', fontSize: '20px', padding: '1px' }}>LEARN MORE</span>
                            </Button>
                        </NavLink>
                    </Col>
                </Row>
    
            </div>
        </>
    );
};
export { WhyInstarepHome };
export default WhyInstarep;
