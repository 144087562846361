import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useNavbar } from '../Context/NavabarContext';


const TopNav = styled.div`
  overflow: hidden;
  text-align: center;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -15px;

  @media (max-width: 767px) {
    display: none;
  }
 
`;

const StyledNavLink = styled(NavLink)`
  float: left;
  display: block;
  color: black;
  text-align: center;
  padding: 14px 48px;
  text-decoration: none;
  font-size: 19px;
  border-bottom: 1px solid gray;

  @media (max-width: 767px) {
    font-size: 14px;
    padding: 10px 28px;
  }

    @media (min-width: 768px) and (max-width: 991px) {
         font-size: 18px;
    padding: 16px; 
        }


    @media (min-width: 991px) and (max-width: 1191px) {
  font-size: 18px;
    padding: 18px; 
  }

  &:hover {
    border-bottom: 2px solid black;
  }

  &.active {
    border-bottom: 2px solid black;
  }

  @media (max-width: 767px) {
    float: none;
    width: 100%;
    box-sizing: border-box;

    &.active {
      background-color: #ddd;
    }
  }
`;

const MobileNav = styled.div`
  display: none;
  
  @media (max-width: 767px) {
    justify-content: flex-start;
    text-align:left;
    margin-left: 0px !important;
    display: block;
    width: 100%;
 
  }
`;

const Dropdown = styled.div`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

const ResponsiveNav = () => {
    const { isOpen, selectedOption, toggleDropdown, handleNavLinkClick } = useNavbar();

    return (
        <>
        <TopNav>
            <StyledNavLink
                exact
                to="/"
                onClick={() => handleNavLinkClick('HOME')}
            >
                HOME
            </StyledNavLink>
            <StyledNavLink
                    to="/offer"
                onClick={() => handleNavLinkClick('OUR ROLES')}
            >
                OUR ROLES
            </StyledNavLink>
            <StyledNavLink
                to="/framework"
                onClick={() => handleNavLinkClick('FRAMEWORK')}
            >
                FRAMEWORK
                </StyledNavLink>
                <StyledNavLink
                    to="/why-instarep-home"
                    onClick={() => handleNavLinkClick('WHY INSTAREP')}
                >
                    WHY INSTAREP
                </StyledNavLink>
                <StyledNavLink
                    to="/contact-us"
                    onClick={() => handleNavLinkClick('CONTACT US')}
                >
                    CONTACT US
                </StyledNavLink>
             </TopNav>
            <MobileNav className="mx-5 mt-3">
                <button onClick={toggleDropdown}
                    style={{ border: 'none', textDecoration: 'none', fontSize: '18px', borderBottom: '1px solid gray', backgroundColor:'transparent' }}
                >{selectedOption || 'Menu'}</button>
                <Dropdown isOpen={isOpen}>
                    <StyledNavLink
                        exact
                        to="/"
                        onClick={() => handleNavLinkClick('HOME')}
                    >
                        HOME
                    </StyledNavLink>
                    <StyledNavLink
                    to='/offer'
                        onClick={() => handleNavLinkClick('OUR ROLES')}
                    >
                        OUR ROLES
                    </StyledNavLink>
                    <StyledNavLink
                        to="/framework"
                        onClick={() => handleNavLinkClick('FRAMEWORK')}
                    >
                        FRAMEWORK
                    </StyledNavLink>
                    <StyledNavLink
                        to="/why-instarep-home"
                        onClick={() => handleNavLinkClick('WHY INSTAREP')}
                    >
                        WHY INSTAREP
                    </StyledNavLink>
                    <StyledNavLink
                        to="/contact-us"
                        onClick={() => handleNavLinkClick('CONTACT US')}
                    >
                        CONTACT US
                    </StyledNavLink>
                </Dropdown>
            </MobileNav>
       </>
    );
};

export default ResponsiveNav;
