import React from 'react';
import styled from 'styled-components';
//import CloseIcon from '@mui/icons-material/Close';
import image from '../Images/333-removebg-preview.png'
import ForwardIcon from '../Images/ForwardArrow.png';
import { Button } from 'reactstrap';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import ResponsiveNav from './ResponsiveNav';


const WorkflowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #d8f3dc; /* Light green color */
  padding: 10px ;
   border: 2px dashed black;
   max-width:1100px;
`;

const StyledArrowImage = styled.img`
  width: 50px;
  height: 50px;
  transform: ${({ rotate }) => (rotate ? 'rotate(90deg)' : 'none')};

  @media (max-width: 767px) {
    transform: rotate(90deg);
  }
`;

const ArrowComponent = ({ rotate }) => {

    return (
        <StyledArrowImage
            src={ForwardIcon}
            alt="Forward Arrow"
            rotate={rotate}
        />
    );
};

const StepContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
    @media (max-width: 767px) {
        flex-direction: column;
        text-align:flex-center;}

`;

const StepBlock = styled.div`
  width: 100%;
  height: 100px;
  background-color: #fff; /* White color for blocks */
  border: 1px solid #ccc; /* Gray border for blocks */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  text-align: center;
  border-radius:10px;

  
    @media (min-width: 768px) and (max-width: 991px) {
      
           font-size: 11px;
             height: 70px;
       }


    @media (min-width: 991px) and (max-width: 1191px) {
  font-size: 13px;
     width: 70%;
  height: 80px;
   margin: 0 5px;
  }

`;
const StepBox = styled.div`
  width: fit-content;
  height: 28px;
  background-color: #d8f3dc; 
  verticalAlign:middle;
  text-align: center;
  padding:5px 15px 30px 15px;

`;

const Workflow = () => {
    return (
        <>
            <ResponsiveNav />
        <div className="d-flex flex-column justify-content-center align-items-center  px-0 " style={{ flex: 1, minHeight: 'calc(100vh - 50px)', marginBottom:'30px'}}>
                <div style={{ border: "1px solid black" }} className="px-3 shadow text-dark text-center d-flex flex-column justify-content-center align-items-center ">
                <NavLink tag={Link} className="text-dark text-center d-flex flex-column justify-content-center mt-2 ">
            
                <p
                    color="dark"
                    style={{
                        borderRadius: '30px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '5px 15px 5px 15px',
                        backgroundColor: 'black',
                        color:'white',
                       
                      

                    }}
            
                >
                
                    <span style={{ verticalAlign: 'middle', fontSize: '16px', paddingInlineEnd: '5px' }}>Discovery Call</span>
                </p>
            </NavLink>
            <ArrowComponent rotate={true} />
            <p style={{textAlign:'left', marginBottom:0} }>Design the job description as per the need of the client</p>
        <WorkflowContainer>
            <StepContainer>
                    <StepBlock><p><b>Shortlisting</b> of candidates</p></StepBlock>
           
                    <ArrowComponent />
     

                    <StepBlock><p><b>Interviewed</b> by the Specialized Panel</p></StepBlock>
         
                    <ArrowComponent />
            

                <StepBlock><b>Onboarding</b></StepBlock>
           
                    <ArrowComponent />
             

                <StepBlock><b>Training</b></StepBlock>
                
                    <ArrowComponent />
               
                    <StepBlock><b>Evaluation</b></StepBlock>
            </StepContainer>

            </WorkflowContainer>
            <ArrowComponent rotate={true} />
            <StepBox ><p>Interviews<b> by the client</b></p></StepBox>
            <ArrowComponent rotate={true} />
            <StepBox><p><b>Onboard</b> to the client</p></StepBox>
            <ArrowComponent rotate={true} />
            <StepBox className='mb-5' style={{border:'1px dashed black'} }><p>Customer<b> Support & Counsel</b></p></StepBox>
              

                </div>
                <img
                    src={image}
                    alt="Forward Arrow"
                    className="d-none d-md-block"
                    style={{
                        width: '150px', // Adjust the width to your preference
                        height: '150px', // Maintain the aspect ratio
                        position: 'absolute',
                        bottom: '-100px',
                        right: '10px',

                    }}
                />
            </div>
       
        </>
    );
};



export default Workflow;



//export default Framework;