import React, { createContext, useContext, useState } from 'react';

const NavbarContext = createContext();

export const NavbarProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('HOME');

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleNavLinkClick = (option) => {
        setSelectedOption(option);
        toggleDropdown();
    };

    const contextValue = {
        isOpen,
        selectedOption,
        toggleDropdown,
        handleNavLinkClick,
    };

    return (
        <NavbarContext.Provider value={contextValue}>
            {children}
        </NavbarContext.Provider>
    );
};

export const useNavbar = () => {
    const context = useContext(NavbarContext);
    if (!context) {
        throw new Error('useNavbar must be used within a NavbarProvider');
    }
    return context;
};