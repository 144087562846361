import React from 'react'; 
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {
    ManageAccounts as ManageAccountsIcon,
    RecordVoiceOver as RecordVoiceOverIcon,
    SensorOccupied as SensorOccupiedIcon,
    AccountCircle as AccountCircleIcon,
} from '@mui/icons-material';
import styled from 'styled-components';
import Overlay from './Overlay';
import image from '../Images/333-removebg-preview.png'

import ArrowBackIosIcon from '../Images/ArrowBack.png';



const Icon = styled.img`
  position: absolute;
  display: inline-block;
  top: 23px;
  left: 30px;
 height:43px;
 width:'25px';

  cursor: pointer;
  z-index: 3;

    @media (max-width: 767px) {
    font-size: 36px!important;
   top: 23px;
     left: 10px;
  }
`;

const Text = styled.p`
 position: absolute;
  font-size: 21px;
  margin-left: 14px;
   top: 29px;
  left: 46px;

    @media (max-width: 767px) {
    font-size: 16px;
 top: 32px;
     left: 20px;
  }
`;

const AboutUs = () => {
    const navigate = useNavigate();
  
      
    return (
        <div>
            <Overlay>
                <div className=" d-flex align-items-center justify-content-center ">
                    <Icon src={ArrowBackIosIcon}
                        onClick={() => { navigate('/'); }} />
                    <Text onClick={() => { navigate('/'); }}>BACK</Text>
                </div>

         

                <Container className="mt-5 p-0" style={{ textAlign: 'justify' }}>
                    <Row className="d-flex justify-content-center align-items-center">
                    
                 
                        <Col xs={12} lg={7}>
                            <article >
                            <p style={{ fontSize: "21px" }}><b>ABOUT US</b></p>
                           
                            Welcome to InstaRep, your trusted partner in staffing solutions.
                            With a commitment to excellence, we specialize in providing top-tier professionals for the roles that drive your business forward.
                        </article>
                    </Col>
                    </Row>
        
                    <Row className="mt-4 d-flex justify-content-center align-items-center">
                   

                    
                        <Col className="d-flex " xs={12} lg={7}>
                       
                        <article>
                        <h6><b>Marketing Associates</b></h6>
                        
                            Elevate your marketing efforts with our handpicked marketing associates.
                            They bring a wealth of experience and a deep understanding of industry trends to help you craft and execute winning marketing strategies.
                        </article>
                    </Col>
                </Row>
                    <Row className="mt-4 d-flex justify-content-center align-items-center">


                        <Col className="d-flex " xs={12} lg={7}>
               
                        <article>
                                <h6><b>Sales Representatives</b></h6>
                        
                            Our sales representatives are the driving force behind your revenue growth.
                            They are highly skilled in identifying opportunities, building relationships,
                            and closing deals, ensuring a steady stream of success for your business.
                        </article>
                    </Col>
                </Row>
                    <Row className="mt-4 d-flex justify-content-center align-items-center">

                  

                        <Col className="d-flex " xs={12} lg={7}>
                   
                        <article>
                                <h6><b>Account Managers</b></h6>
                        
                            Building and maintaining client relationships is at the heart of what we do.
                            Our dedicated account managers are adept at nurturing client connections, ensuring satisfaction, and facilitating long-term partnerships.
                        </article>
                    </Col>
                </Row>
                    <Row className="mt-4 mb-5 d-flex justify-content-center align-items-center">

               

                        <Col className="d-flex " xs={12} lg={7}>
                 
                        <article>
                                <h6><b>Accountants</b></h6>
                        
                            Numbers are the backbone of any successful enterprise.
                            Our skilled accountants bring precision, accuracy, and financial expertise to keep your business in sound financial health.
                        </article>
                    </Col>
                </Row>
                </Container>
            </Overlay>
        </div>
    );
};

export default AboutUs;
