import React from 'react'; 
import { Container, Col} from 'reactstrap';
import { Link } from 'react-router-dom';

import styled from 'styled-components';
import About from '../Images/Homepage.png';
import Hire from '../Images/hire.jpeg';
import Learning from '../Images/training.jpeg';
import Career from '../Images/hiring.jpeg';
import Climate from '../Images/climate_change-removebg-preview.png';
import Food from '../Images/food security.jpeg';
import { useNavGrid } from '../Context/NavGridContext';


   


// You can now use these icons in your code

const DisplayMenu = styled.div`
  display: block;
  position: absolute;
   
  height: 100%;  
  
  right: 0;
  width: 100%;
  max-width: 400px;
  z-index: 100;


 
`;

const StyledButton = styled.button`
    border: 1px solid transparent;
    border-radius: 20px;
    display: flex;
    flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
    align-items: center;
    justify-content: center;
    padding: 6px;
    margin: 5px;
    width: 90px;
    height: 70px;
    color: black;
    transition: background-color 0.3s;
    text-decoration: none; 
    text-align: center;

  

 
`;

const LinkWithIcon = ({ to, text, icon, onClick }) => (
    <Col>
        <StyledButton
            as={Link}
            to={to}
            onClick={onClick} 
        >
            {icon}
            <span style={{ fontSize: '16px', width: '160px' }}>{text}</span>
        </StyledButton>
    </Col>
);


const LinkRow = styled.div`
display:flex;
flex-direction:row;
   flex-wrap: wrap;
  justify-content: center; 
  align-items: flex-start;
  margin-bottom: 60px;
      margin-right: -29px;
    margin-left: 21px;
`;



const ImageIcon = ({ src }) => {
    return (
        <img
            src={src}
            alt="Forward Arrow"
            style={{
                width: '100px', // Adjust the width to your preference
                height: '100px', // Maintain the aspect ratio
                border: '1px solid black',
                borderRadius: '10px',

            }}
        />
    );
};

const GridLinks = () => {
    const { showResults, setShowResults } = useNavGrid();


    const handleClick = () => setShowResults(false);
    if (showResults) {
    return (
       
            <DisplayMenu>
        <Container fluid="sm"
            className=" border shadow"
            style={{
                maxWidth: '350px',
                border: '1px solid #e0e0e0',
                borderRadius: '8px',
                padding: '20px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
                marginRight: '7px',
                marginTop: '5px',
                backgroundColor:"white",
                              

            }}>
                <LinkRow style={{marginTop:'25px'} }>
            
                    <LinkWithIcon to="/about-us" text="About" icon={<ImageIcon src={About} style={{ marginBottom: '5px' }} />} style={{marginRight:'-10px'}} onClick={handleClick }/>                               
                    <LinkWithIcon to="/hiring" text="Hire" icon={<ImageIcon src={Hire} style={{ marginBottom: '5px' }} />} onClick={handleClick} />
                </LinkRow>
            <LinkRow>
                    <LinkWithIcon to="/learning" text="Learning" icon={<ImageIcon src={Learning} style={{ marginBottom: '5px' }} />} onClick={handleClick} />
            <LinkWithIcon to="/careers" text="Careers" icon={<ImageIcon src={Career} style={{ marginBottom: '5px' }} />} onClick={handleClick} />
                     </LinkRow>
               
           
            <hr></hr>

                <LinkRow style={{ marginTop: '55px', marginBottom: '20px' }}>
                    <LinkWithIcon to="/climate" text="Climate Change"  icon={<ImageIcon src={Climate} style={{ marginBottom: '5px' }} />} onClick={handleClick} />
                    <LinkWithIcon to="/food" text="Food Security" icon={<ImageIcon src={Food} style={{ marginBottom: '5px' }} />} onClick={handleClick} />
                </LinkRow>
           
            </Container>
        </DisplayMenu>
       
        ); }
    else {
        return null;
    }
};

export default GridLinks;