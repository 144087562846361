import React, { createContext, useContext, useState } from 'react';

const NavGridContext = createContext();

export const NavGridProvider = ({ children }) => {
    const [showResults, setShowResults] = useState(false)
    const showGrid = () => {

        setShowResults(!showResults);

    }

    const contextValue = {
        showResults,
        setShowResults,
        showGrid,
    };

    return (
        <NavGridContext.Provider value={contextValue}>
            {children}
        </NavGridContext.Provider>
    );
};

export const useNavGrid = () => {
    const context = useContext(NavGridContext);
    if (!context) {
        throw new Error('useNavbar must be used within a NavbarProvider');
    }
    return context;
};