import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Overlay from './Overlay';


import ArrowBackIosIcon from '../Images/ArrowBack.png';



const Icon = styled.img`
  position: absolute;
  display: inline-block;
  top: 23px;
  left: 30px;
 height:43px;
 width:'25px';

  cursor: pointer;
  z-index: 3;

    @media (max-width: 767px) {
    font-size: 36px!important;
   top: 23px;
     left: 10px;
  }
`;

const Text = styled.p`
 position: absolute;
  font-size: 21px;
  margin-left: 14px;
   top: 29px;
  left: 46px;

    @media (max-width: 767px) {
    font-size: 16px;
 top: 32px;
     left: 20px;
  }
`;

const Learning = () => {
    const navigate = useNavigate();
    return (
        <div>
            <Overlay>
                <div className=" d-flex align-items-center justify-content-center ">
                    <Icon src={ArrowBackIosIcon}
                        onClick={() => { navigate('/'); }} />
                    <Text onClick={() => { navigate('/'); }}>BACK</Text>
                </div>
         




                <Container className="mt-5" style={{ textAlign: 'justify' }}>
                    <Row className="d-flex justify-content-center align-items-center">
               
                        <Col className="d-flex " xs={12} lg={7}>
                    
                            <article>
                                <p style={{ fontSize: '21px' }}><b>LEARNING AND DEVELOPMENT</b></p>
                                <h6><b>Trainings</b></h6>

                                InstaRep Academy provides training sessions based on specific needs, utilizing a thoughtfully crafted curriculum. 
                                <h6 className="mt-4"><b>Evaluation</b></h6>

                            The evaluation involves a post-training test designed to assess their understanding of the material covered during the program.
                            This test serves several essential purposes.
                        </article>
                       
                        </Col>
                    </Row>
                        <Row className="mt-4 d-flex justify-content-center align-items-center">
                        

                            <Col className="d-flex " xs={12} lg={7}>
                          
                            <article>
                             <h6><b>Knowledge Assessment</b></h6>

                            The test assesses the candidates' grasp of the technical and operational knowledge imparted during the training program.
                            </article>
                        </Col>
                    </Row>
                        <Row className="mt-4 d-flex justify-content-center align-items-center">
                    
                            <Col className="d-flex " xs={12} lg={7}>
                          
                            <article>
                                    <h6><b>Skill Validation</b> </h6>

                                It evaluates the candidates' ability to apply the acquired knowledge and skills in practical scenarios relevant to their roles.
                            </article>
                        </Col>
                    </Row>
                        <Row className="mt-4  d-flex justify-content-center align-items-center">
                    

                            <Col className="d-flex " xs={12} lg={7}>
                           
                            <article>
                                    <h6><b>Problem-Solving</b></h6>

                                The test includes scenarios and challenges that mirror real-world situations, allowing candidates to demonstrate their problem-solving abilities.
                            </article>
                        </Col>
                    </Row>
                        <Row className="mt-4  d-flex justify-content-center align-items-center">
                     


                            <Col className="d-flex " xs={12} lg={7}>
                     
                            <article>
                                    <h6><b>Performance Benchmarking </b></h6>
                                The results of the test are used to benchmark each candidate's performance against predefined standards,
                                ensuring that they meet the minimum requirements expected for their positions.
                            </article>
                        </Col>
                    </Row>
                        <Row className="mt-4 d-flex justify-content-center align-items-center">
                        


                        <Col className="d-flex " xs={12} lg={7 }>
                         
                            <article>
                                            <h6><b>Feedback and Improvement</b></h6>
                                The evaluation process provides valuable feedback to candidates, helping them identify areas where they may need additional support or development.
                            </article>
                        </Col>
                    </Row>

               
                    <Row className="mt-4  d-flex justify-content-center align-items-center">

                

                        <Col className="d-flex " xs={12} lg={7}>
                   
                        <article>
                                    <h6><b>Individualized Learning Plans</b></h6>
                            Based on the results of the post-training test, candidates receive individualized feedback and support. If any gaps in knowledge or skills are identified,
                            candidates are provided with targeted training and resources to address these areas.
                            We believe in continuous improvement and are committed to helping each employee reach their full potential
                        </article>
                    </Col>
                </Row>
                    <Row className="mt-4 mb-5 d-flex justify-content-center align-items-center">

           

                        <Col className="d-flex " xs={12} lg={7}>
                 
                        <article>
                                    <h6><b>Ongoing Monitoring and Growth</b></h6>

                            Even after the post-training evaluation, our commitment to our employees' growth and development continues. We regularly monitor their performance, conduct performance reviews,
                            and provide opportunities for additional training and skill enhancement as needed.
                            This ensures that our employees remain at the forefront of industry trends and continually contribute to our clients' success.
                        </article>
                    </Col>
                </Row>
            
                </Container>
            </Overlay>
        </div>
    );
};

export default Learning;