import React  from 'react';
import { NavbarBrand, NavItem, NavLink} from 'reactstrap';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import whatsapp from '../Images/q.jpeg';
import MenuIcon from '../Images/NavIcon.png';
import { useNavGrid } from '../Context/NavGridContext';

const NavMenu = () => {
    const { showGrid } = useNavGrid();

    const logoStyle = {
        fontSize: '24px',
        fontFamily: 'Arial, sans-serif',
        display: 'flex',
        alignItems: 'center', // Align text vertically in the center
        '@media only screen and (max-width: 300px)': {
            display: 'none !important',
        },
    
    };

    const instaStyle = {
        fontWeight: 'normal', // Normal font weight for "insta"
        fontStyle: 'normal',
        '@media only screen and (max-width: 300px)': {
            display: 'none !important',
        },
    };

    const repStyle = {
        fontWeight: 'bold', // Bold font weight for "Rep"
        marginRight: '5px',
        fontStyle: 'normal',
     
        
        '@media only screen and (max-width: 300px)': {
            display: 'none !important',
            fontSize: '14px !important',
        },
    };
  

    return (
        <>
            <header>
                <Container fluid className="p-0" style={{ height:'fit-content',boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', backgroundColor:'rgb(229, 228, 226,0.6)' }}>
                    <Row>
                        <Col xs={6} md={6} >
                            
                        </Col>
                        <Col xs={6} md={6} className="d-flex flex-row-reverse align-items-center">
                            <ul className="navbar-nav d-flex flex-row align-items-center">
                                <NavItem className="hide-logo">
                                
                                    <NavbarBrand tag={Link} to="/" style={logoStyle} >
                                        <span style={instaStyle}>insta</span>
                                        <span style={repStyle}>Rep</span>
                                    </NavbarBrand>
                                </NavItem>
                                <NavItem >
                                    <NavLink className="text-dark m-0 p-0" onClick={showGrid} ><img src={MenuIcon} alt="Logo" style={{ width: '50px', height: '50px', marginRight: '12px' }} /></NavLink>
                                </NavItem>
                            </ul>
                        </Col>
                    </Row>
                   </Container>
    
    </header>
 
    </>
        
                 );
};

export default NavMenu; 
