import { Container, Row, Col } from 'react-bootstrap';

import image from '../Images/1111-removebg-preview.png';

import whatsapp from '../Images/q.jpeg';
import flag from '../Images/flag.png';
import ResponsiveNav from './ResponsiveNav';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { NavbarBrand, NavItem } from 'reactstrap';
import { useNavGrid } from '../Context/NavGridContext';
import MenuIcon from '../Images/NavIcon.png';
import GridLinks from "./GridLinks";
import "./Offer.css";


const ContactUs = () => {
    const { showGrid } = useNavGrid();

    const logoStyle = {
        fontSize: '24px',
        fontFamily: 'Arial, sans-serif',
        display: 'flex',
        alignItems: 'center', // Align text vertically in the center

    };

    const instaStyle = {
        fontWeight: 'normal', // Normal font weight for "insta"
        fontStyle: 'normal',
    };

    const repStyle = {
        fontWeight: 'bold', // Bold font weight for "Rep"
        marginRight: '5px',
        fontStyle: 'normal',
    };

    return (
        <>
           
                <header>
                    <Container fluid className="p-0" style={{ height: 'fit-content', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', backgroundColor: 'rgb(229, 228, 226,0.6)' }}>
                        <Row>
                            <Col xs={6} md={6} className="d-flex flex-row justify-content-start ">
                            <NavLink tag={Link} className="ps-3 text-dark text-center d-flex flex-column justify-content-center mt-1" to="https://wa.me/15517990700" target='_blank'>
                  
                            <Button
                                
                                className="px-3 py-1"
                                style={{
                                    borderRadius: '30px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '5px 10px 5px 10px',
                                    margin: 'auto',
                                  backgroundColor:'#25D366', 
                                  border:'none',

                                   
                                }}
                              
                            >
                              
                                <span style={{ verticalAlign: 'middle', fontSize: '18px', paddingInlineEnd: '5px' }}>Discovery Call</span>
                            </Button>
                        </NavLink>
                            </Col>
                            <Col xs={6} md={6} className="d-flex flex-row-reverse align-items-center">
                                <ul className="navbar-nav d-flex flex-row align-items-center">
                                    <NavItem>

                                        <NavbarBrand tag={Link} to="/" style={logoStyle}>
                                            <span style={instaStyle}>insta</span>
                                            <span style={repStyle}>Rep</span>
                                        </NavbarBrand>
                                    </NavItem>
                                    <NavItem >
                                        <NavLink className="text-dark m-0 p-0" onClick={showGrid} ><img src={MenuIcon} alt="Logo" style={{ width: '50px', height: '50px', marginRight: '12px' }} /></NavLink>
                                    </NavItem>
                                </ul>
                            </Col>
                        </Row>
                    </Container>



                <div style={{ marginRight: '41px' }}>{<GridLinks />}</div>
                </header>

        <ResponsiveNav />
        
        <div style={{ flex: 1, minHeight: 'calc(100vh - 50px)' }}>

            <Container className="d-flex  justify-content-center  align-items-center mb-md-5">


                <Row className="d-flex justify-content-center align-items-center">
                
                        <Col xs={12} md={12} lg={5 } xl={5 } className='p-0 mt-0'>
                            <img src={image} alt="home" style={{ alignSelf: 'center' }} className=" m-0 img-fluid" />


                            </Col>
                            <Col xs={12} md={12} lg={9} xl={9} className='p-0 mt-0'>

                        <div className="text-center  d-flex flex-column justify-content-start align-items-center">
                                <a href='/#' className="my-3 responsive-address" style={{ fontSize: '22px' }} >support@instarep.co</a>
                            

                                <p className="my-3 responsive-address d-flex  flex-wrap-wrap" style=
                                    {{ fontSize: '22px' }}>Lembeck Ave, Jersey City, NJ 07305,
                                <div className=" usa mb-3 d-flex flex-column justify-content-end" style={{ zIndex: '3', marginTop: '-83px', marginLeft: '-20px' } }>
                                   
                                        <img src={flag} alt="flag" className="flag" style={{ width: '75.84px', height: '98.88px', marginBottom: "-15px", marginLeft: '18px' }} />
                                        <b >USA</b>
                                    </div>
                                </p>
                                        
                                  
                               
                        </div>

                        
                    </Col>
                </Row>
            </Container>
            </div>
        </>
    );
};

export default ContactUs;
