import React from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import { Outlet } from 'react-router';
import GridLinks from "./GridLinks";

/*const Layout = ({ children }) => {

    return (
       
        <div>

            <NavMenu />
   
            
                {children}
            </Container>
        </div>
    );
};*/


const WithNavLayout = ({ children }) => (
    <>
        <NavMenu />
        <GridLinks />
 
            <Outlet />
      
          
    </>
);

const WithoutNavLayout = ({ children }) =>
( <>
   
    <Outlet />
 
    </>
);

export { WithNavLayout, WithoutNavLayout };

