import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Facebook, Instagram, LinkedIn  } from "@mui/icons-material";
import YouTubeIcon from '@mui/icons-material/YouTube';
import GoogleIcon from '@mui/icons-material/Google';
import { Box } from "@mui/material";


export default function Footer() {
    return (
        <Box mb={0}
        gutterBottom={0}
            component="footer"
            sx={{
              
                position: 'relative',
                bottom: 0,
                left: 0,
                width: '100%',
                height: '50px',
                
               
                backgroundColor: (theme) =>
                    theme.palette.mode === "light"
                        ? theme.palette.grey[100]
                        : theme.palette.grey[800],
                p: 1,
                '@media (max-width: 600px)': {
                    height: '70px', 
                },
            }}
        >
            <Container maxWidth="lg">
                <Grid container spacing={{ xs: 2, md: 5 }} justifyContent={{ xs: 'center', sm:'center', md: 'flex-start' }} alignItems={{ xs: 'flex-start', md: 'center' }}>
                    <Grid item xs={12} sm={12} md={6 }  sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body2" color="text.secondary" sx={{ fontSize: 12, marginRight: 1, marginTop: 0.5 }}>
                            Follow Us
                        </Typography>
                        <Link href="https://www.linkedin.com/" color="inherit" sx={{ marginRight: 1 }}>
                            <LinkedIn sx={{ fontSize: 16 }} />
                        </Link>
                        <Link href="https://www.facebook.com/" color="inherit" sx={{ marginRight: 1 }}>
                            <Facebook sx={{ fontSize: 16 }} />
                        </Link>
                        <Link href="https://www.instagram.com/" color="inherit" sx={{ marginRight: 1 }}>
                            <Instagram sx={{ fontSize: 16 }} />
                        </Link>
                        <Link href="https://www.youtube.com/" color="inherit" sx={{ marginRight: 1 }}>
                            <YouTubeIcon sx={{ fontSize: 16 }} />
                        </Link>
                        <Link href="https://www.google.com/" color="inherit">
                            <GoogleIcon sx={{ fontSize: 16 }} />
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6 }>
                        <Box>
                            <Typography variant="body2" color="text.secondary" align="center" sx={{ fontSize: 12, marginLeft: 1 }}>
                                {"Copyright © "}
                                {new Date().getFullYear()}
                                {" InstaRep. All rights reserved."}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}