import styled from 'styled-components';

const Overlay = styled.div`
position: fixed;
display:'block';
width: 100%;
height: 100%;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: rgba(255,255,255,1);
z-index: 2;
cursor: pointer;
padding: 60px;
overflow: auto;
`;

export default Overlay;