import React, { createContext} from 'react';
import { Route, Routes } from 'react-router-dom';
import { WithNavLayout, WithoutNavLayout } from './components/Layout';
import './custom.css';
import Footer from './components/Footer';
import { NavbarProvider } from './Context/NavabarContext';
import { NavGridProvider } from './Context/NavGridContext';
import AboutUs from "./components/AboutUs";
import GridLinks from "./components/GridLinks";
import Home from "./components/Home";
import WhyInstarep, { WhyInstarepHome } from "./components/WhyInstarep";
import Learning from "./components/Learning";
import Hiring from "./components/Hiring";
import Framework from "./components/Framework";
import ContactUs from "./components/ContactUs";
import Navigation from "./components/Navigation"
import OfferComponent, { OneRole, SecondRole, ThirdRole, Careers } from "./components/OfferComponent"
//import CalenderForm from "./components/CalenderForm"






export const VisibilityContext = createContext();


const App = () => {
    
  
    return (
        <>
            <NavbarProvider>
                <NavGridProvider>
                    
                 <Routes>
                    <Route
                        element={<WithNavLayout />}
                    >
                        <Route index element={<Home />} />

                        <Route path="/grid-links" element={<GridLinks />} />
                        <Route path="/framework" element={<Framework />} />
                        <Route path="/careers" element={<Careers />} />
            
                      
                        <Route path="/why-instarep-home" element={<WhyInstarepHome />} />
                      
                        <Route path="/navigation" element={<Navigation />} />
                        <Route path="/offer" element={<OfferComponent />} />
                      {/*  <Route path="/calender" element={<CalenderForm />} />*/}
                        <Route path="/food" element={<Navigation nav="food" />} />
                            <Route path="/climate" element={<Navigation nav="climate" />} />
                          
                     


                        </Route>

                    <Route
                        element={<WithoutNavLayout />}
                    >
                        <Route path="/one-role" element={<OneRole />} />
                        <Route path="/second-role" element={<SecondRole />} />
                    <Route path="/third-role" element={<ThirdRole />} />
                         
                            <Route path="/why-instarep" element={<WhyInstarep />} />
                            <Route path="/hiring" element={<Hiring />} />
                            <Route path="/learning" element={<Learning />} />
                            <Route path="/about-us" element={<AboutUs />} />
                            <Route path="/contact-us" element={<ContactUs />} />
                    </Route>
                        
                    </Routes>
         
                    <Footer />
                </NavGridProvider>
            </NavbarProvider>
        </>
    );
};

export default App;
